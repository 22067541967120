import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FormControl, InputLabel, Input, Button } from "@material-ui/core"

const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <>
      <Layout location={location} title={siteTitle}>
        <Seo title="Contact" />
        <h1 className="tags-header">Contact</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 20,
            padding: 20,
          }}
        >
          <form style={{ width: "100%" }}>
            <FormControl margin="normal" fullWidth required>
              <InputLabel htmlFor="name">Nom</InputLabel>
              <Input id="name" type="text" />
            </FormControl>

            <FormControl margin="normal" fullWidth required>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input id="email" type="email" />
            </FormControl>

            <FormControl margin="normal" fullWidth required>
              <InputLabel htmlFor="message">Message</InputLabel>
              <Input id="message" type="text" multiline rows={5} />
            </FormControl>

            <Button variant="contained" color="primary" size="medium">
              Envoyer
            </Button>
          </form>
        </div>
      </Layout>
    </>
  )
}

export default Contact

Contact.propTypes = {
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }),
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
